.todoAppContainer {
  background-color: #ffffffd0;
  backdrop-filter: blur(4px);
  padding: 5px;
  width: 310px;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
  box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.1);
}

.wrap {
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  cursor: move;

  .logoWrapper {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    color: #ffffff;
  }
}
