.modalContainer {
  position: absolute;
  top: 2px;
  left: 3px;
  padding: 4px;
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 1;
  width: 97%;
  height: 130px;
  margin: 2px;
  z-index: 5000;
}
