.truck-body {
  display: flex;
  flex-direction: column;
  .head {
    margin: 0 auto;
    background-image: url(../../assets/head.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 290px;
    height: 210px;
  }

  .trailer {
    margin: 2px auto;
    background-image: url('../../assets/bg3.png');
    border: 2px solid #000000;
    border-radius: 3px;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    .elements__container {
      display: flex;
      flex-wrap: wrap;
      height: 10px;
      .z_index {
        z-index: 5;
      }
      .flipped {
        transform: rotate(90deg);
      }
    }
    .div_element {
      cursor: move;
      border: 1px solid #000000;
      border-radius: 2px;
      margin: 1px;
      .dimensions {
        background-color: #000000;
        color: #ffffff;
        font-size: 0.83rem;
        padding: 1px 2px;
        margin: 1px;
        border-radius: 2px;
      }
      .note {
        padding: 3px;
        font-size: 0.8rem;
        color: rgb(54, 54, 54);
      }
      .icons__wrap {
        display: flex;
        align-items: center;
        .delete__icon,
        .rotate__icon {
          transition: all 0.2s ease-in-out;
          display: none;
          margin-left: 3px;
          cursor: pointer;
        }
        .rotate__icon:hover {
          svg {
            transform: scale(1.2);
          }
        }
        .delete__icon:hover {
          transform: scale(1.3);
        }
      }
    }
    .div_element:hover > .icons__wrap > .delete__icon {
      display: block;
    }
    .div_element:hover > .icons__wrap > .rotate__icon {
      display: block;
    }
  }
}

.todoAppContainer {
  position: relative;
  .todoApp {
    position: absolute;
    top: 30px;
    left: 0;
  }
}
